<template>
    <div class="lens-card border-bottom mt-2">
        <b-row class="mb-2 text-center text-sm-left">
            <b-col sm="3" md="2" lg="3" class="pr-3 pr-xl-0">
                <img class="mt-1" src="@/assets/shopping-cart/accessory.svg"
            /></b-col>
            <b-col sm="9" md="10" lg="9" class="pl-3 pl-xl-0">
                <h5
                    class="mt-1 mb-1 font-weight-bold"
                    :class="{'text-danger': checkRegulatoryItems(accessory.shoppingCartItemId)}"
                >
                    {{ accessory.description }}
                    <span v-if="accessory.partNumber">({{ accessory.partNumber }})</span>
                </h5>
                <p v-if="checkRegulatoryItems(accessory.shoppingCartItemId)" class="text-danger">
                    <span v-html="t(regulatoryCheckNoteText)"> </span>
                </p>
                <h6 v-if="canShowPrice">
                    {{ accessory.price | toCurrency }}
                    <span class="ml-2 font-weight-bold">
                        {{ (accessory.price * accessory.quantity) | toCurrency }}</span
                    >
                </h6>
                <div class="d-flex flex-wrap">
                    <h6
                        v-if="accessory.automaticallyAdded"
                        class="align-self-center font-weight-bold mb-0"
                    >
                        {{ accessory.quantity }} {{ t('includedWithLens') }}
                    </h6>
                    <template v-else>
                        <div class="quantity-control mr-auto mr-sm-3">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <span
                                        v-if="readonly"
                                        class="align-self-center text-small font-weight-bold mr-2"
                                    >
                                        {{ t('checkout_Quantity') }}:
                                    </span>
                                    <b-button
                                        v-else
                                        class="p-1"
                                        variant="light"
                                        :disabled="isDiscountButtonDisabled || !canModifyAccesory"
                                        @click="changeQuantity(-1)"
                                        ><b-icon
                                            class="align-middle"
                                            icon="dash"
                                            variant="dark"
                                            font-scale="2"
                                    /></b-button>
                                </b-input-group-prepend>

                                <b-form-input
                                    :class="{'text-center': !readonly}"
                                    type="text"
                                    min="0.00"
                                    inputmode="numeric"
                                    :disabled="!canModifyAccesory"
                                    :formatter="formatQuantity"
                                    :plaintext="readonly"
                                    v-model="accessory.quantityWrapper"
                                    lazy-formatter
                                    lazy
                                ></b-form-input>

                                <b-input-group-append>
                                    <b-button
                                        v-if="!readonly"
                                        class="p-1"
                                        variant="light"
                                        :disabled="isIncreaseButtonDisabled || !canModifyAccesory"
                                        @click="changeQuantity(1)"
                                    >
                                        <b-icon
                                            class="align-middle"
                                            icon="plus"
                                            variant="dark"
                                            font-scale="2"
                                    /></b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <h6
                            v-if="accessory.minimumQuantity"
                            class="align-self-center font-weight-bold mb-0"
                        >
                            {{
                                t('checkout_MinQuantity', {
                                    minimumQuantity: accessory.minimumQuantity,
                                })
                            }}
                        </h6>
                    </template>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapActions, mapMutations} from 'vuex';
import {debounce} from 'lodash';
import {LOCALE_CURRENCY_FORMAT} from '@/config';
import {MAX_ALLOWED_QUANTITY} from '@/store/modules/cart';

export default {
    name: 'AccesoryCard',
    components: {},
    props: {
        accessory: {
            type: Object,
            default: () => {},
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        failedRegulatoryCheckItems: {
            type: Array,
            required: false,
            default: () => [],
        },
        regulatoryCheckNoteText: {
            type: String,
            default: 'regulatoryCheckNoteText',
        },
    },
    data() {
        return {};
    },
    computed: {
        isDistributorSurgeonMode() {
            return this.checkPermissions({
                [this.PERMISSIONS.DISTRIBUTOR_MODE]: [this.PERMISSIONS_VALUES.SURGEON],
            });
        },
        hasPricingPermission() {
            return this.checkPermissions({
                [this.PERMISSIONS.PRICING]: [this.PERMISSIONS_VALUES.ENABLED],
            });
        },
        canShowPrice() {
            return (
                this.hasPricingPermission &&
                !this.isDistributorSurgeonMode &&
                !this.accessory.automaticallyAdded
            );
        },
        canModifyAccesory() {
            return !this.isDistributorSurgeonMode;
        },
        isDiscountButtonDisabled() {
            return this.accessory.quantity <= this.accessory.minimumQuantity;
        },
        isIncreaseButtonDisabled() {
            return this.accessory.quantity >= MAX_ALLOWED_QUANTITY;
        },
    },
    watch: {
        'accessory.quantityWrapper': {
            handler: function (value) {
                this.setLoading(true);
                this.accessory.quantity = this.cleanQuantity(value);
                this.saveQuantity();
            },
        },
    },
    methods: {
        ...mapActions({
            updateItem: 'cart/updateItem',
        }),
        ...mapMutations({
            setLoading: 'cart/setLoading',
        }),
        saveQuantity: debounce(async function () {
            try {
                const {success, error} = await this.updateItem(this.accessory);
                this.setLoading(false);
                if (!success) throw error;
            } catch (error) {
                this.$bvToast.toast(
                    error?.errors?.join(' ') || 'Something went wrong updating quantity',
                    {
                        title: error?.errorCodeDescription || this.t('warning'),
                        variant: 'warning',
                        solid: true,
                    }
                );
            }
        }, 100),
        changeQuantity(quantity) {
            this.accessory.quantityWrapper = this.formatQuantity(
                this.accessory.quantity + quantity
            );
        },
        formatQuantity(value) {
            value = this.cleanQuantity(value);
            if (value > MAX_ALLOWED_QUANTITY)
                return MAX_ALLOWED_QUANTITY.toLocaleString(LOCALE_CURRENCY_FORMAT);
            if (value <= this.accessory.minimumQuantity)
                return this.accessory.minimumQuantity.toLocaleString(LOCALE_CURRENCY_FORMAT);
            return value.toLocaleString(LOCALE_CURRENCY_FORMAT);
        },
        cleanQuantity(value) {
            return Number(String(value).replace(/[^0-9]/g, ''));
        },
        checkRegulatoryItems(itemId) {
            return this.failedRegulatoryCheckItems?.includes(itemId) ?? false;
        },
    },
};
</script>
<style lang="scss" scoped>
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

@include media-breakpoint-up(xs) {
    .quantity-control {
        margin: 0 auto;
        width: 145px;
    }
}
@include media-breakpoint-up(sm) {
    .quantity-control {
        margin: initial;
    }
}

/* START: hiding Arrows/Spinners from input type number*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
/* END: hiding Arrows/Spinners from input type number*/
</style>
